import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

export default function Awards() {
  const list = [
    "https://images.icc-cricket.com/image/upload/prd/daknjdvkajs7yxn1bhvy.pdf",
    "https://images.icc-cricket.com/image/upload/prd/p5b2nl80ti1iqztm3l47.pdf",
  ];

  return (
    <BodyWrapper>
      <Container>
        <H3>Awards</H3>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Paragraph>
        <Section>
          <Ul>
            {list.map((url) => (
              <Li>
                <Href href={url} rel="noopener" target="_blank">
                  {url}
                </Href>
              </Li>
            ))}
          </Ul>
        </Section>
      </Container>
    </BodyWrapper>
  );
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  font-size: 14px;
  padding: 1%;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;

const H3 = styled.div`
  font-weight: 700;
  line-height: 1.2;
  font-size: 23px;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: CWC, system-ui, sans-serif;
  color: rgb(81 98 111 / 1);
`;

const Section = styled.section`
  padding-bottom: 1rem;
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  @media (min-width: 1024px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const Href = styled.a`
  font-size: 15px;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #00838c;
  text-decoration-line: underline;
  color: rgb(81 98 111)
}
`;
